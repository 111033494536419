<template>
    <div class="module-cont-box second-bg">
        <div class="module-title">
            <div class="main-title">CASE INTRODUCTION</div>
            <span>案例介绍</span>
        </div>
        <div class="module-main-cont">
            <img :src="curCase.img" style="width:100%;height:350px;" class="md-hide" />
            <div class="cont-bg md-hide" >
                <h2>{{curCase.title}}</h2>
                <a-button type="primary" size="large" style="padding:0 50px;">查看详情</a-button>
            </div>
            <ul class="case-list">
                <li v-for="(item,index) in caseList" :class="{cur:item.id==curCase.id}">
                    <h3>{{ item.title }}</h3>
                    <p>{{item.desc }}</p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default{
        name:'caseIntroduction',
        data(){
            return{
                caseList:[],
                curCase:{}
            }
        },
        created(){
            this.loadCaseInfo();
        },
        methods:{
            loadCaseInfo(){
                // this.$service.get("getCaseIntroduction")
                this.$service.Ers76.listContent({"sid":"24612476b0c011efba8546cc601cd7e2","status":1}).then(resp=>{
                    console.log("getCaseIntroduction", resp);
                    this.caseList=resp.data;
                    if(resp.data.length>0){
                        this.curCase=resp.data[0];
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>

    .module-title{
        margin-bottom: 20px;padding-left:30px;
        .main-title{font-size: 20px; color: @primary-color; font-weight: bold;margin-bottom: 5px;}
    }
    .module-main-cont{
        position: relative;
        img{border-radius:5px;}
        .cont-bg{
            background-color:rgba(51, 63, 74, 0.7);position: absolute; width:100%;height: 100%;left:0;top:0;padding:50px 0px 0px 50px;
            h2{color: #fff;margin-bottom: 20px;}
        }
        ul.case-list{
            list-style: none;
            li{
                background-color: #fff;  margin-bottom: 15px;border-radius: 3px;padding: 5px 15px; 
                p{color:#aaa; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;font-size: 12px;}
            }
            li.cur{
                border-bottom:4px solid @primary-color;
                h3{color:@primary-color;}
            }
        }
    }
    @media (min-width: 991px){
        ul.case-list{            
            position:absolute;right: 30px; top:-35px;
            li{width: 360px;}
        }
  }
</style>